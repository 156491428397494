// 获取列表
const getListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsGrantedCommunity`
// 导出列表
const exportListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/exportCommunitySubject`

// 应用版块
const getSectionURL = `${API_CONFIG.controlBaseURL}subjectSection/subjectSectionSelectTo`
// 获取项目已授权应用列表 (不分页)
const getGrantedApplicationListURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/listSubjectsSimpleCommunity`
// 设置版块应用排序
const applicationSortURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/sortCommunitySubjects`

// 应用列表 select2
const getApplicationURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/communitySubjectSelectTo`

// 编辑 - 项目授权应用
const editGrantedURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/updateCommunitySubjectGranted`
// 查询详情 - 项目授权应用
const queryGrantedURL = `${API_CONFIG.controlBaseURL}subjectCommonShu/getCommunitySubjectGranted`

// 编辑 - 服务信息
const updateServiceInfoURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!update.action`
// 查询详情 - 服务信息
const queryServiceInfoURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!view.action`
// 获取一级标签列表
const getSuperiorTagListURL = `${API_CONFIG.butlerBaseURL}orgTag/getSuperiorTagByTagName`
// 获取关联组织标签列表
const getOrgTagSearchListURL = `${API_CONFIG.butlerBaseURL}orgTag/commonOrgTagSearch`
// 编辑 - 维修分类（收费标准）
const updateFeesURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!updateFees.action`
// 查询详情 - 维修分类（收费标准）
const getFeesURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!getFees.action`
// 查询应急分类
const querySubTypeURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!querySubType.action`
// 查询全部的一级标签
const getAllFirstLabel = `${API_CONFIG.butlerBaseURL}user/orgTag/current/user/superiorTag`
// 根据一级标签获取所有的二级标签
const getTagIdsByFirstIds = `${API_CONFIG.butlerBaseURL}user/orgTag/list/tagIdsByFirstIds`

export {
  getListURL,
  exportListURL,
  getSectionURL,
  getGrantedApplicationListURL,
  applicationSortURL,
  getApplicationURL,
  editGrantedURL,
  queryGrantedURL,
  updateServiceInfoURL,
  queryServiceInfoURL,
  getSuperiorTagListURL,
  getOrgTagSearchListURL,
  updateFeesURL,
  getFeesURL,
  querySubTypeURL,
  getAllFirstLabel,
  getTagIdsByFirstIds
}
