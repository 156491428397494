<template>
  <div class="ApplicationLibraryProjectList-wrapper">
    <list exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #headerSlot>
        <v-button text="设置版块应用排序"
                  permission="sortSub"
                  @click="applicationSort" />
        <v-button text="设置应用暂停时间"
                  permission="pauseSub"
                  @click="pauseTime" />
        <v-button text="新增"
                  permission="add"
                  @click="create" />
      </template>
      <template #searchSlot>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"
                   :subjoin="communitySubjoin"
                   @onChange="communityChange"></v-select2>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="regionParams"></v-select2>
        <v-input label="应用名称"
                 v-model="searchParams.subName"
                 :maxlength="10" />
        <v-select label="所属类别"
                  v-model="searchParams.subHandle"
                  :options="subHandleOps" />
        <v-select label="所属版块"
                  v-model="searchParams.sectionId"
                  :options="sectionOps"
                  optionGroup />
        <v-select label="应用类型"
                  v-model="searchParams.subType"
                  :options="subTypeOps" />
        <v-select label="应用状态"
                  v-model="searchParams.subStatus"
                  :options="statusOps" />
        <v-datepicker label="创建时间段"
                      type="rangedatetimer"
                      :maxDate="new Date()"
                      :startTime.sync="searchParams.inTimeFrom"
                      :endTime.sync="searchParams.inTimeTo" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑"
                  type="text"
                  permission="update"
                  @click="edit(scope.row)" />
        <v-button text="二维码"
                  type="text"
                  permission="qrcode"
                  @click="showQrcode(scope.row)" />
        <v-button text="服务信息"
                  type="text"
                  v-if="scope.row.subHandle !== 1"
                  permission="setSubCommunity"
                  @click="serviceInfo(scope.row)" />
      </template>
    </list>
    <dialog-qrcode ref="dialogQrcode"></dialog-qrcode>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { createImgVNode } from 'common/vdom'
import { getListURL, exportListURL } from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, statusMap, setStatusOps } from '../map'
import { getSectionOps } from '../common'
import DialogQrcode from '../DialogQrcode'

export default {
  name: 'ApplicationLibraryProjectList',
  components: {
    DialogQrcode
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      statusOps: setStatusOps(1),
      sectionOps: [],
      communityParams,
      regionParams,
      searchParams: {
        communityId: '',
        regionId: '',
        subName: '',
        subHandle: undefined,
        sectionId: undefined,
        subType: undefined,
        subStatus: undefined,
        inTimeFrom: '',
        inTimeTo: ''
      },
      headers: [
        {
          prop: 'subImg',
          label: '应用图标',
          formatter: (row, prop) => createImgVNode(this, row, prop)
        },
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'subHandleText',
          label: '所属类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'sectionName',
          label: '所属版块'
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subSort',
          label: '应用排序',
          align: 'right'
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => statusMap[row.subStatus]
        },
        {
          prop: 'pauseServiceTime',
          label: '应用暂停时间'
        },
        {
          prop: 'inTime',
          label: '创建时间'
        }
      ]
    }
  },
  async created () {
    if (this.userType !== '101') {
      this.resetSectionOps()
    } else {
      this.sectionOps = await getSectionOps()
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    communitySubjoin () {
      return {
        regionId: this.searchParams.regionId
      }
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'applicationLibraryProjectForm'
      })
    },
    edit (row) {
      let subId = row.subId
      if (row.selfSub) {
        this.$router.push({
          name: 'applicationLibraryProjectForm',
          query: {
            subId,
            communityId: row.communityId
          }
        })
      } else {
        this.$router.push({
          name: 'applicationLibraryProjectEditForm',
          query: {
            subId: row.communitySubId,
            communityId: row.communityId
          }
        })
      }
    },
    applicationSort () {
      this.$router.push({
        name: 'applicationSortForm'
      })
    },
    pauseTime () {
      this.$router.push({
        name: 'pauseTimeProjectForm'
      })
    },
    showQrcode (row) {
      this.$refs.dialogQrcode.showQrcode(row)
    },
    serviceInfo (row) {
      this.$router.push({
        name: 'serviceInfoForm',
        query: {
          subId: row.subCommunityId,
          communityName: row.communityName
        }
      })
    },
    resetSectionOps () {
      this.sectionOps = [{
        label: '全部',
        value: undefined,
        options: [{
          label: '全部',
          value: undefined
        }]
      }]
    },
    async communityChange (community, isBack) {
      if (this.userType !== '101') {
        if (community && community.id) {
          this.sectionOps = await getSectionOps({
            params: {
              communityId: community.id
            }
          })
        } else {
          this.resetSectionOps()
        }
        if (!isBack) {
          this.searchParams.sectionId = undefined
        }
      }
    }
  }
}
</script>
