<template>
  <div class="DialogQrcode-wrapper">
    <el-dialog
      width="500px"
      :title="`${qrcodeInfo.subName} - 二维码`"
      :visible.sync="qrcodeVisible"
      @closed="closeQrcodeDialog"
    >
      <div class="dialog-qrcode" v-if="qrcodeInfo.subId">
        <div class="title">APP扫一扫跳转服务</div>
        <div class="value">二维码值：{{ qrcodeInfo.subId }}</div>
        <div class="img" :id="qrcodeInfo.subId"></div>
        <v-button text="保存到本地" :width="200" @click="downloadQrcode" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { downloadHelper } from 'common/utils'

export default {
  name: 'DialogQrcode',
  data () {
    return {
      qrcodeVisible: false,
      qrcodeInfo: {}
    }
  },
  methods: {
    showQrcode (row) {
      let { subId, subName } = row
      subId = `sub_id:${subId}`
      this.qrcodeInfo = {
        subId,
        subName
      }
      this.qrcodeVisible = true
      this.$nextTick(() => {
        new QRCode(subId, { // eslint-disable-line
          text: subId,
          width: 200,
          height: 200
        })
      }, 20)
    },
    downloadQrcode () {
      const qrcodeEle = document.getElementById(this.qrcodeInfo.subId)
      const src = qrcodeEle.children[1].src
      const fileName = this.qrcodeInfo.subName
      downloadHelper.downloadBySrc({
        src,
        fileName
      })
      this.qrcodeVisible = false
    },
    closeQrcodeDialog () {
      this.qrcodeInfo = {}
    }
  }
}
</script>

<style scope lang="scss">
.DialogQrcode-wrapper {

  .dialog-qrcode {

    .title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    .img {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

  }

}
</style>
